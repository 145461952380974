import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEdit from '@/components/GeneralEdit';
export default {
  name: 'Index',
  components: {
    GeneralEdit: GeneralEdit
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      rules: {
        b_username: [{
          required: true,
          message: this.$t('rules.common.required'),
          trigger: 'blur'
        }, {
          min: 0,
          max: 50,
          message: this.$t('rules.common.maxlength50'),
          trigger: 'blur'
        }],
        b_name: [{
          required: true,
          message: this.$t('rules.common.required'),
          trigger: 'blur'
        }],
        b_user_status: [{
          required: true,
          message: this.$t('rules.common.required'),
          trigger: 'change'
        }]
      },
      editForm: null
    };
  },
  watch: {
    'editForm.b_supervisor_id': {
      handler: function handler(val, oldVal) {
        if (this.getClientId() != 13) {
          return;
        }
        var b_supervisor_id = val;
        var __this = this;
        if (!isNull(b_supervisor_id)) {
          this.b_supervisor_id_item.options.forEach(function (item) {
            if (item.value == b_supervisor_id) {
              // 格式：code - name
              __this.editForm.b_supervisor_code = __this.getCodeFromCodeName(item.label);
            }
          });
        } else {
          __this.editForm.b_supervisor_code = '';
        }
      }
    }
  },
  computed: {
    url: function url() {
      if (this.$route.params.id) {
        return {
          getUrl: '/user',
          method: 'put'
        };
      } else {
        return {
          getUrl: '/user',
          method: 'post'
        };
      }
    }
  },
  methods: {
    onEditFormCreated: function onEditFormCreated(editForm) {
      if (this.getClientId() == 13) {
        this.initEditFormTableColumns(editForm);
      }
    },
    getDetailSuccess: function getDetailSuccess(editForm) {
      this.editForm = editForm;
    },
    initEditFormTableColumns: function initEditFormTableColumns(editForm) {
      if (this.getClientId() != 13) {
        return;
      }
      var currentTable = editForm.currentTable;
      var __this = this;
      currentTable.forEach(function (item) {
        if (item.code == 'b_supervisor_id') {
          __this.b_supervisor_id_item = item;
        }
      });
    },
    getCodeFromCodeName: function getCodeFromCodeName(s) {
      var i = s.indexOf('-');
      if (i > 0) {
        return s.substring(0, i).trim();
      } else {
        return s;
      }
    }
  }
};